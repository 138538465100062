import { Markdown, Paragraph } from "grommet";
import { useEffect, useState } from "react";

function General(props) {
    const [markdown, setMarkdown] = useState("")

    useEffect(() => {
        fetch(props.link).then(s => s.text()).then(t => setMarkdown(t))
    }, [props.link])
    
    return (<Markdown components={{
        "p": {
            "component": Paragraph,
            "props": {
                "style": {
                    maxWidth: "800px"
                }
            }
        }
    }}>
        {markdown}
    </Markdown>)
}

export default General;